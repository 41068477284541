import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./Contacts.scss";

const Contacts = ({ darkMode }) => {
  const serviceID = process.env.REACT_APP_SERVICEID;
  const templateID = process.env.REACT_APP_TEMPLATEID;
  const userID = process.env.REACT_APP_USERID;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const toastifySuccess = () => {
    toast("Thank You  We will Contact You as soon as possible!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const toastifySuccessError = () => {
    toast("Try Again later please!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback dangger",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    // Destrcture data object

    const { name, phone, email, subject, description } = data;
    try {
      const templateParams = {
        name,
        phone: `${phone}`,
        email,
        subject,
        description,
      };

      await emailjs.send(serviceID, templateID, templateParams, userID);

      reset();
      toastifySuccess();
    } catch (e) {
      reset();
      toastifySuccessError();
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={darkMode ? "contacts" : "contacts-light"}>
      <div className="text-center">
        <h1>Contact Me</h1>
        <p>
          Please fill out the form and describe you project needs and I'll
          contact you as soon as possible.
        </p>
      </div>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              {/* NAME INPUT */}
              <div className="text-center">
                <input
                  required
                  type="text"
                  className="form-control-BW"
                  placeholder="Name"
                  name="name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Please enter your Name!",
                    },
                  })}
                />
                {errors.name && (
                  <span className="errorMessage">{errors.name.message}</span>
                )}
                <div className="line"></div>
              </div>
              {/* PHONE INPUT */}
              <div className="text-center">
                <input
                  type="tel"
                  className="form-control-BW"
                  placeholder="Phone Number"
                  name="phone"
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "Please enter your Phone Number !",
                    },
                  })}
                />
                <div className="line"></div>
              </div>

              {/* EMAIL INPUT */}
              <div className="text-center">
                <input
                  type="email"
                  className="form-control-BW"
                  placeholder="Email"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                {errors.email && (
                  <span className="errorMessage">
                    Please enter a valid email address !
                  </span>
                )}
                <div className="line"></div>
              </div>
              {/* SUBJECT INPUT */}
              <div className="text-center">
                <input
                  type="text"
                  className="form-control-BW"
                  placeholder="Subject"
                  name="subject"
                  {...register("subject", {
                    required: {
                      value: true,
                      message: "Please enter a Subject !",
                    },
                  })}
                />
                {errors.subject && (
                  <span className="errorMessage">{errors.subject.message}</span>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              {/* DESCRIPTION */}
              <div className="text-center">
                <textarea
                  type="text"
                  className="form-control-BW"
                  placeholder="Please describe shortly you project..."
                  name="description"
                  {...register("description", {
                    required: true,
                  })}
                ></textarea>
                {errors.message && (
                  <span className="errorMessage">
                    Please enter a description !
                  </span>
                )}
                <div className="line"></div>
              </div>

              <button
                id="sub-btn"
                className={
                  darkMode
                    ? "btn-main-offer contact-btn"
                    : "btn-main-offer contact-btn-light"
                }
                type="submit"
              >
                Contact Me
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contacts;
