import RightSider from "./RightSider";
import { ProgramData } from "./ProgramData";
import { useEffect, useState } from "react";
import "./RightSider.scss";
import { CloseOutlined } from "@ant-design/icons";
import { LoadingComponent } from "../LoadingComponent";

const Protfolio = ({ darkMode }) => {
  const [filteredPost, setFilteredPost] = useState(null);
  const [portfolioData, setPortfoliodata] = useState(ProgramData);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const getPost = (id) => {
    setFilteredPost(portfolioData?.filter?.((el) => el.id === id)[0]);
  };

  useEffect(() => {
    const imagePromises = portfolioData?.map((item) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = item.img;
        img.onload = () => resolve();
      });
    });

    Promise.all(imagePromises).then(() => {
      setImagesLoaded(true);
    });
  }, [portfolioData]);

  console.log(imagesLoaded, " imagesLoaded");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!imagesLoaded && <LoadingComponent darkMode={darkMode} />}
      <div
        className="mainDiv"
        style={{
          width: "100%",
          display: "flex",
          height: "calc(100vh - 100px)",
        }}
      >
        <div className="div1" style={{ width: "20%" }}>
          <div className={darkMode ? "sidebar" : "sidebar-light"}>
            <div className="data-menu">
              {portfolioData?.map((el) => (
                <div
                  key={el?.id}
                  className={filteredPost?.id === el?.id ? "item-open" : "item"}
                  onClick={() => getPost(el?.id)}
                >
                  <div className="imge-div">
                    <img src={el.img} width={49} height={40} alt="promo-img" />
                  </div>
                  <div className="DESCRIP">{el.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="div2" style={{ width: "60%" }}>
          <div className={darkMode ? "main" : "main-light"}>
            <div className={darkMode ? "header-MAIN" : "header-MAIN-light"}>
              <div className="des">Web Builders Protfolio</div>
              {filteredPost !== null ? (
                <div
                  className="clear"
                  onClick={() => {
                    setPortfoliodata(ProgramData);
                    setFilteredPost(null);
                  }}
                >
                  <CloseOutlined />
                </div>
              ) : (
                <div style={{ width: 23 }}> </div>
              )}
            </div>
            {filteredPost !== null ? (
              <div
                style={{ cursor: "pointer" }}
                className="card"
                onClick={() => window.open(filteredPost?.link, "_blank")}
              >
                <div className={darkMode ? "header" : "header-light"}>
                  <img
                    className="img-nain"
                    src={filteredPost?.img}
                    alt="promo-img"
                  />
                  {filteredPost?.title}
                </div>
                <div className="img">
                  <img
                    className="main-img"
                    src={filteredPost?.img}
                    alt="promo-img"
                  />
                </div>
                <div className="footer-BLOG">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.replace("/#contacts");
                    }}
                    className="ordeer-btn"
                  >
                    Order Now
                  </button>
                </div>
              </div>
            ) : (
              portfolioData?.map((el) => (
                <div
                  key={el?.id}
                  style={{ cursor: "pointer" }}
                  className="card"
                  onClick={() => window.open(el?.link, "_blank")}
                >
                  <div className="header">
                    <img className="img-nain" src={el.img} alt="promo-img" />
                    {el.title}
                  </div>
                  <div className="img">
                    <img className="main-img" src={el.img} alt="promo-img" />
                  </div>
                  <div className="footer-BLOG">
                    {/* <Link smooth={true} to="/" href="#contacts  " offset={-110}> */}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.replace("/#contacts");
                      }}
                      smooth={true}
                      offset={-90}
                      className="ordeer-btn"
                      // onClick={() =>
                      //   (window.location = "mailto:bruno.wydooghe6@gmail.com")
                      // }
                    >
                      Order Now
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="div3" style={{ width: "20%" }}>
          <RightSider
            darkMode={darkMode}
            setPortfoliodata={setPortfoliodata}
            ProgramData={ProgramData}
          />
        </div>
      </div>
    </>
  );
};

export default Protfolio;
