import React from "react";
import { RotatingSquare } from "react-loader-spinner";

export const LoadingComponent = ({ darkMode }) => {
  return (
    <div className={darkMode ? "loadingContainer" : "loadingContainerLight"}>
      <RotatingSquare
        height="150"
        width="150"
        color={darkMode ? "#fff" : "#283238"}
        ariaLabel="rotating-square-loading"
        strokeWidth="4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};
